/* General Styling */
.services-section {
    font-family: 'Poppins', sans-serif;
    text-align: center;
    background-color: #f8f9fa;
  }
  
  .services-section h2 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 40px;
  }
  
  /* Service Row Styling */
  .service-row {
    margin-bottom: 50px;
    background: #ffffff;
    border-radius: 15px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  /* Heading & Paragraph */
  .service-row h3 {
    font-size: 1.8rem;
    font-weight: 600;
    color: #007bff;
    margin-bottom: 15px;
  }
  
  .service-row p {
    font-size: 1.1rem;
    color: #555;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  /* Image Styling */
  .service-row .img-fluid {
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .service-row .img-fluid:hover {
    transform: scale(1.05);
  }
  
  /* Button Styling */
  .service-row .btn-primary {
    font-size: 1rem;
    font-weight: 600;
    padding: 12px 24px;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    background-color: #007bff;
    border: none;
  }
  
  .service-row .btn-primary:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .services-section h2 {
      font-size: 2rem;
    }
  
    .service-row h3 {
      font-size: 1.5rem;
    }
  
    .service-row p {
      font-size: 1rem;
    }
  
    .service-row .btn-primary {
      font-size: 0.9rem;
      padding: 10px 20px;
    }
  }
  