.afterschool-robotics-page .glow-heading {
    color: #004085;
    text-shadow: 2px 2px 5px #ced4da;
}

.robotics-section img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.robotics-section h3 {
    color: #007bff;
    margin-bottom: 20px;
}

.robotics-section ul {
    list-style-type: square;
    padding-left: 20px;
}

.robotics-section ul li {
    font-size: 1rem;
    color: #495057;
    margin-bottom: 10px;
}

button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1.2rem;
}
