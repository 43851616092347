/* Header.css */


/* Ensure the navbar background is light and the text is dark */
/* .navbar {
  background-color: #f8f9fa;
  padding: 0.5rem 1rem;
 
  
} */
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

body {
  padding-top: 110px;
}


/* Logo styling with space from the border */
.navbar-brand img {
  height: 80px;
}

/* Navigation links */
.navbar-nav .nav-link {
  color: #343a40; /* Dark text color */
  padding: 0.5rem 1rem;
  font-weight: 500;
  transition: color 0.3s ease;
}

/* Active link styling */
.navbar-nav .nav-link.active {
  color: #007bff; /* Bootstrap primary color */
  font-weight: 700;
}

/* Hover effect for navigation links */
.navbar-nav .nav-link:hover {
  color: #0056b3; /* Slightly darker blue for hover effect */
}

/* Remove underlines from links */
.navbar-nav .nav-link {
  text-decoration: none;
}

/* Media query for responsive design */
@media (max-width: 992px) {
  .navbar-nav .nav-link {
    padding: 0.5rem 0; /* Adjust padding for smaller screens */
  }
}

.close-icon {
  font-size: 24px;
  font-weight: bold;
}
