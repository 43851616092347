.contact-page {
    padding: 50px 0;
    background-color: #f8f9fa;
  }
  
  .contact-page h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #343a40;
  }
  
  .contact-page .lead {
    font-size: 1.2rem;
    margin-bottom: 40px;
  }
  
  .contact-page h4 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #495057;
  }
  
  .contact-page p {
    font-size: 1rem;
    color: #6c757d;
  }
  
  .contact-page form {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-page form .form-label {
    font-weight: 600;
    color: #495057;
  }
  
  .contact-page form .form-control {
    border-radius: 5px;
  }
  
  .contact-page form .btn-primary {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 600;
    transition: background-color 0.3s ease;
  }
  
  .contact-page form .btn-primary:hover {
    background-color: #0056b3;
  }
  .map-container {
    margin-top: 20px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  