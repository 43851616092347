/* General styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}

/* Welcome section styles */
.welcome-section {
  min-height: 100vh;
  background-image: url('Home.jpg'); /* Replace with your image URL */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 20px;
}

/* Mobile-friendly background scrolling */
@media (max-width: 768px) {
  .welcome-section {
    background-attachment: scroll; /* Prevents issues on mobile */
  }
}


/* Content Box */
.welcome-section .content {
  max-width: 600px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background for readability */
  border-radius: 8px;
}

.welcome-section h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.welcome-section p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

/* Call-to-action buttons */
.cta-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.cta-button {
  padding: 12px 25px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  color: white;
  transition: background-color 0.3s ease;
}

.cta-button.call-us {
  background-color: #007bff;
}

.cta-button.whatsapp-us {
  background-color: #25d366;
}

.cta-button:hover {
  background-color: #0056b3;
}

.cta-button.whatsapp-us:hover {
  background-color: #128c7e;
}

/* Responsive Design */
@media (max-width: 768px) {
  .welcome-section h1 {
    font-size: 2rem;
  }

  .welcome-section p {
    font-size: 1rem;
  }

  .cta-button {
    padding: 10px 20px;
  }
}


/* Additional Styling for Other Sections */




@keyframes glow {
  0% {
    text-shadow: 0 0 5px rgba(0, 123, 255, 0.5), 0 0 10px rgba(0, 123, 255, 0.3);
  }
  50% {
    text-shadow: 0 0 15px rgba(0, 123, 255, 0.9), 0 0 30px rgba(0, 123, 255, 0.7);
  }
  100% {
    text-shadow: 0 0 5px rgba(0, 123, 255, 0.5), 0 0 10px rgba(0, 123, 255, 0.3);
  }
}

/* Tournament Section */
.tournament-reviews-section {
  padding: 40px 0;
  background-color: #f9f9f9;
}

.tournament-reviews-section h2 {
  margin-bottom: 30px;
}

.video-container {
  position: relative;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  height: 0;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* International Events Section */
.international-events-section {
  padding: 40px 0;
  background-color: #f4f4f4;
  border-top: 3px solid #007bff; /* Border color */
  border-bottom: 3px solid #007bff; /* Border color */
}

.section-title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #007bff;
}

.event-col {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.event-title {
  margin-bottom: 15px;
  font-size: 1.5rem;
}

.event-description {
  margin-bottom: 15px;
}

.event-img {
  border-radius: 8px;
}

.news-section h2 {
  font-weight: bold;
  color: #333;
}

.news-card {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  margin-bottom: 20px;
}

.news-card:hover {
  transform: translateY(-10px);
}

.video-frame {
  width: 100%;
  height: 300px;
}

.news-card .card-body {
  padding: 20px;
}

.news-card .card-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.news-card p {
  font-size: 1rem;
  color: #555;
  margin-top: 10px;
}

.whatsapp-float {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25D366;
  color: white;
  border-radius: 50%;
  padding: 12px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  z-index: 1000;
  text-decoration: none;
}

.whatsapp-float:hover {
  background-color: #1ebe5d;
}

.whatsapp-icon {
  font-size: 32px;
}

/* General Styles */
body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0.5;
  background-color: #f8f9fa;
  color: #333;
}

/* Hero Section */
.hero {
  position: relative;
  height: 90vh;
  background: url('./bc24.jpg') no-repeat center center/cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.hero-content {
  position: relative;
  z-index: 2;
  max-width: 600px;
}

.hero h1 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.hero p {
  font-size: 1.3rem;
  margin-bottom: 20px;
}

.hero-buttons {
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-top: 20px;
}

.btn-primary, .btn-secondary {
  display: inline-block;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}

.btn-primary {
  background-color: #007bff;
  color: white;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-secondary {
  background-color: #28a745;
  color: white;
}

.btn-secondary:hover {
  background-color: #1e7e34;
}

/* Features Section */
.features {
  padding: 60px 20px;
  text-align: center;
}

.features h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #007bff;
}

.feature-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.feature-card {
  background: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  text-align: center;
}

.feature-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #28a745;
}

.feature-card p {
  font-size: 1rem;
  color: #555;
}

/* WhatsApp Floating Button */
.whatsapp-float {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #25d366;
  color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.whatsapp-float:hover {
  transform: scale(1.1);
}

.whatsapp-icon {
  color: white;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.5rem;
  }

  .hero p {
    font-size: 1.1rem;
  }

  .hero-buttons {
    flex-direction: column;
  }

  .btn-primary, .btn-secondary {
    width: 100%;
    text-align: center;
  }

  .feature-cards {
    flex-direction: column;
    align-items: center;
  }
}

/* Robotics Kits Section */
.robotics-kits {
  text-align: center;
  background: white;
  color: black;
  padding: 50px 20px;
  margin-top: 40px;
}
.kits-content h2 {
  font-size: 2rem;
}
.kits-content p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}
.kits-content .btn-primary {
  background: white;
  color: #007bff;
  font-weight: bold;
}

