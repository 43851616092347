.programs-page .glow-heading {
    color: #004085;
    text-shadow: 2px 2px 5px #ced4da;
  }
  
  .programs-section .card-img-wrapper {
    height: 280px; /* Adjusted to make image medium-sized */
    overflow: hidden;
    border-radius: 10px 10px 0 0;
  }
  
  .programs-section .program-img {
    width: 100%;
    height: 100%;
    object-fit: scale-down; /* Ensures image fits without stretching or cropping */
  }
  
  .card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: none;
  }
  
  .card-body h3 {
    color: #007bff;
  }
  
  .card-body p {
    font-size: 0.95rem;
    color: #495057;
  }
  