.robotics-tournament-page .glow-heading {
    color: #004085;
    text-shadow: 2px 2px 5px #ced4da;
  }
  
  .tournament-section .tournament-img {
    height: 300px; /* Adjust height as needed */
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .tournament-section h3,
  .events-section h3,
  .past-events-section h3,
  .inspire-challenge-section h3 {
    color: #007bff;
    margin-bottom: 5px;
  }
  
  .tournament-section p,
  .events-section p,
  .past-events-section p,
  .inspire-challenge-section p {
    font-size: 1.1rem;
    color: #495057;
  }
  
  .event-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: none;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .event-card .card-title {
    color: #495057;
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .event-card .btn-link {
    color: #007bff;
    font-size: 1rem;
    text-decoration: underline;
    padding: 0;
  }
  
  .event-card .btn-link:hover {
    color: #0056b3;
    text-decoration: none;
  }
  
  .contact-section h4 {
    color: #007bff;
    margin-bottom: 20px;
  }
  
  .contact-section a {
    color: #004085;
    text-decoration: none;
  }
  
  .contact-section a:hover {
    text-decoration: underline;
  }
  

  .international-events-section {
    padding: 2rem;
    background-color: #f8f9fa;
    border-radius: 8px;
  }
  
  .section-title {
    font-weight: bold;
    margin-bottom: 2rem;
    font-size: 2rem;
    color: #333;
  }
  
  /* .event-col {
    margin-bottom: 2rem;
    padding: 1rem;
  }
  
  .event-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #0056b3;
  }
  
  .event-description {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
    color: #666;
  }
  
  .event-img {
    max-width: 100%;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  } */
  .past-events-section h3 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 2rem;
  }
  
  .event-card {
    background-color: #f9f9f9;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
  }
  
  .event-card .card-title {
    font-size: 1.75rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  .event-card p {
    font-size: 1.125rem;
    color: #555;
  }
  
  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    margin-top: 1rem;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  
  button {
    font-size: 1rem;
    color: #007bff;
    margin-top: 1rem;
  }
  

  .inspire-challenge-section {
    background-color: #f0f8ff;
    border-radius: 8px;
    padding: 2rem;
  }
  
  .section-title {
    font-weight: bold;
    font-size: 2rem;
    color: #0056b3;
  }
  
  .section-subtitle {
    font-size: 1.2rem;
    color: #666;
    margin-top: 0.5rem;
  }
  
  .inspire-logo {
    max-width: 100px;
    margin: 0 auto;
  }
  
  .content-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #0056b3;
  }
  
  .content-description {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .key-pillars-title {
    font-size: 1.2rem;
    margin-top: 1.5rem;
    color: #0056b3;
  }
  
  .key-pillars-list {
    padding-left: 1.5rem;
    font-size: 1rem;
    color: #555;
  }
  