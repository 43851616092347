.mobile-robotics-lab-page .glow-heading {
    color: #004085;
    text-shadow: 2px 2px 5px #ced4da;
  }
  
  .mobile-lab-section .community-img {
    height: 300px; /* Adjust height as needed */
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .mobile-lab-section h3 {
    color: #007bff;
    margin-bottom: 20px;
  }
  
  .mobile-lab-section p {
    font-size: 1.1rem;
    color: #495057;
  }
  
  .community-engagements-section h3 {
    color: #007bff;
    margin-bottom: 40px;
  }
  
  .community-engagement-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: none;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .community-engagement-card .card-title {
    color: #495057;
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .community-engagement-card .btn-link {
    color: #007bff;
    font-size: 1rem;
    text-decoration: underline;
    padding: 0;
  }
  
  .community-engagement-card .btn-link:hover {
    color: #0056b3;
    text-decoration: none;
  }
  