.signup-form {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    background: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
  }
  
  .signup-form h2 {
    margin-bottom: 15px;
  }
  
  .signup-form input,
  .signup-form select,
  .signup-form textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .signup-form button {
    background: #28a745;
    color: white;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
  }
  
  .signup-form button:hover {
    background: #218838;
  }
  