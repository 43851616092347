/* About.css */

/* General styles for the About page */
.about-page {
  background-color: #f0f4f8;
  padding: 3rem 0;
}

h1.display-4 {
  color: #343a40;
  font-weight: 700;
}

p.lead {
  color: #495057;
  font-size: 1.25rem;
}

.text-muted {
  color: #6c757d;
}

/* Card styling */
.card {
  border-radius: 0.5rem;
  border: none;
}

.card.shadow-sm {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-title {
  color: #007bff;
  font-size: 1.5rem;
}

.card-text {
  color: #495057;
}

/* Responsive layout adjustments */
@media (max-width: 768px) {
  .card {
    margin-bottom: 1rem;
  }
}
